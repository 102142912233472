
import React from "react";
import { Col, Row} from 'react-bootstrap';
import Wes from "../media/Wes.webp"
import Frank from "../media/Frank.webp"
import Jack from "../media/Jack.webp"
import Naweed from "../media/Naweed.webp"
import Alex from "../media/Alex.webp"

const TeamPage = () => {

    return (

        <div className="teamPage"><br/><br/>
       
            <Row  >
          <Col>
          
            <img className="teamPics" alt="Wes Gates" src={Wes}></img>
            <br/><br/><br/><h3> Wes Gates</h3><br/>
            <p>Wes Gates is the principal of Gates Consulting, which has been providing IT services to clients for 21 years. His firm contracted with the Arizona School Risk Retention Trust, Inc. (the Trust) in 2013 to provide cybersecurity services to school districts, including development of the cybersecurity services offered to Trust members and facilitating cyber claims/cyber incident response efforts.  

 

Wes’ work on behalf of the Trust led to the development of cyberpools and a cyber toolkit which includes a risk assessment, phishing/training, vulnerability scanning, incident response support, and additional consulting as needed. Additionally, he helped put together a group-purchasing program for multi-factor authentication  (MFA) and endpoint detection and response (EDR) in order to support clients with their cyber liability exposures. 
<br/><br/>
 

Wes has over 35 years of IT experience, with 21 of those years at the Trust. He has worked in various roles, including chief information officer, developer, chief information security officer (CISO), and network engineer. Wes has a full stack of development skills, network administration, helpdesk support, and cybersecurity training. </p>
          </Col>
          <Col>
        
          <img alt="Frank Bejar" src={Frank}></img> 
          <br/><br/><br/><h3>Frank Bejar</h3><br/>
          <p>As our lead consultant, Frank possesses over a decade of invaluable experience and has been instrumental in guiding organizations to successful project outcomes.
Frank boasts a comprehensive skill set, covering Project Management, System Administration, Identity and Access Management, Asset Management, Network Administration, and Security Engineering, alongside proficiency in Microsoft 365, Azure, Governance, Risk Management, and Compliance.
</p>
          <p>In addition to his extensive hands-on experience, Frank holds a Bachelor of Science in Information Technology from Grand Canyon University. His professional certifications include: </p>
          <ul>
            <li>Certified Information Systems Security Professional (CISSP) </li>
            <li>Microsoft Certified: Security, Compliance, and Identity </li>
            <li>Juniper Networks Certified Professional, Security (JNCIA-SEC) </li>
            <li>CompTIA Security+ Certification </li>
            <li>CompTIA Network+ Certification </li>
            <li>CompTIA Project+ Certification </li>
          </ul>
          </Col>
          {/* <Col>
          <img alt="Johnny Prescott" src={Johnny}></img>
          <br/><br/><br/><h3>Johnny Prescott</h3><br/>
          <p>    Johnny has worked in many roles in information technology for over a decade as a network manager, IT director, forensics consulting, and cybersecurity consultant. He has been solely focused on cybersecurity for six years, starting with forensics work on cyber claims and currently providing cybersecurity services for school districts. 
</p><br/>Johnny has the following experience:<br/><br/><ul>
                <li>VMWare virtualization, vSphere, ESX, VM Connect server, VMWare view (virtual desktops) / Microsoft Hyper-V virtualization / 15 years supporting Active Directory Enterprise</li>
                <li>12 years supporting Citrix Winframe / Metaframe / SQL Server / SharePoint Server / Exchange Server / MS Access / Clearwell </li>
                <li>20+ years’ experience with IBM and compatible hardware</li>
                <li>Advanced experience and understanding of cyber security, network firewalls, routers, switches, LAN, WAN, VoIP PBX, DNS, WINS, DHCP, HOSTS, TCP/IP, IPX-SPX, SNMP, SMTP, VPN, FTP, Cisco, SonicWALL, Juniper, HP, Cabletron, Adtran, ECI Telecom, Avaya, Riverbed</li></ul>
          </Col><br/> */}</Row>
          <Row>
          <Col>
            
            <img alt="Jack Tiffany" src={Jack}></img>
            <br/><br/><br/><h3>Jack Tiffany</h3><br/>
            <p>Jack has a Bachelor of Science in computer science (cybersecurity) from Arizona State University. He works as a cybersecurity analyst, providing phishing and vulnerability scans for school districts. Jack’s educational focus in cybersecurity focused on the following: </p>
            <br/>
            <ul>
                <li>Computer Systems Security </li>
                <li>Computer Network Security</li>
                <li>Computer and Network Forensics</li>
            </ul>
          </Col>
          <Col>
          
          <img alt="Naweed Nabi" src={Naweed}></img>
          <br/><br/><br/><h3>Naweed Nabi</h3><br/>
          <p>
Naweed has had experience in cyber security and awareness training for a large franchise company in Phoenix. He is a full stack developer, and he has had experience in developing various different web applications for companies throughout his career. He specialized in secure development techniques and testing. As a cyber security analyst, he provides value by administering security tools which assist in lowering overall risk for organizations. Naweed also leads efforts in providing effective reporting and trends to members.
  </p>

            
          </Col>
          <Col>
          
          <img alt="Alex Robles" src={Alex}></img>
          <br/><br/><br/><h3>Alex Robles</h3><br/>
          <p>    Alex is an experience IT professional with a diverse experience. He has been a network administrator playing key roles in projects such as multi-factor authentication implementation, network access control, backup solutions, role-based access controls, asset management and single sign-on. He has extensive experience with a multitude of software and network platforms. He has strong presentation and communication skills as he helps educate clients and engineer IT solutions to reduce risk and adhere to compliance standards. He has the following certifications:
</p>
<ul>
                <li>CompTIA A+ Certification </li>
                <li>CompTIA Network+ Certification</li>
                <li>CompTIA Security+ Certification </li>
            </ul>
          </Col>
          </Row>
        </div>


    );
};

export default TeamPage;