import React from "react";


const Services = () => {


    return (
        <div>
            <br/><br/>
            <h1>Services</h1><br/>
            <p>A complete list of services we offer to all of our clients.</p><br/>
            <table className="tableStyles">
                <tr>
                    <th><h3>Service</h3></th>
                    <th><h3>What it does</h3></th>
                </tr>
                <br/>
                <tr>
                    <td><b>Vulnerability scans</b> </td>
                    <td>External scan of networks for vulnerabilities, prioritized report of any vulnerabilities identified during scan, and remediation guidance to safeguard against exploitation of known vulnerabilities </td>
                </tr>
                <tr>
                    <td><b>Risk assessments</b> </td>
                    <td>High-level overview of IT infrastructure to determine cyber posture, identify gaps in best practices, and provide remediation guidance </td>
                </tr>
                <tr>
                    <td><b>Phishing and Training Campaigns</b></td>
                    <td>Phishing emails sent to member staff to test and raise awareness, report of campaign outcomes, and remediation guidance to safeguard against predatory phishing </td>
                </tr>
                <tr>
                    <td><b>Incident Response Planning</b> </td>
                    <td>Guidance creating a written incident response plan to help streamline response during an actual event </td>
                </tr>
                <tr>
                    <td><b>IT Policy Templates</b> </td>
                    <td>Access to downloadable, customizable policies and assistance customizing selected policies to members’ specific systems and resources </td>
                </tr>
                <tr>
                    <td><b>MFA and EDR Group Purchasing</b> </td>
                    <td>Bulk purchasing to make multi-factor authentication (MFA) and endpoint detection and response (EDR) resources more affordable for members </td>
                </tr>
                <tr>
                    <td><b>vCISO Consulting</b> </td>
                    <td>On-demand access to a virtual chief information security officer (vCISO) for cybersecurity guidance on topics such as network architecture, access control, encryption, etc. </td>
                </tr>
                <tr>
                    <td><b>Tabletop Exercises</b> </td>
                    <td>Scenario exercises designed to guide you through a possible “real-life” incident in preparation for an actual event </td>
                </tr>
                <tr>
                    <td><b>Claims Consulting</b> </td>
                    <td>Post-claim assistance with remediation, plus management of vendors and/or third-party claims adjusters </td>
                </tr>
                <tr>
                    <td><b>Education</b> </td>
                    <td>A library of webinars, presentations, and white papers to help educate your staff, members, and clients.  </td>
                </tr>
            </table>
        </div>
    );
};

export default Services;