
import './App.css';
import React from "react";
import { Routes, Route } from 'react-router-dom'
import ContactUs from './pages/ContactUs';
import Navigation from './components/Nav';
import AboutUs from './pages/About';
import TeamPage from './pages/Team';
import Footer from './components/Footer';
import Services from './pages/Services';


const App = () => {

  

  return (
   
    <div  className="PageContainer">
 
    <Navigation/>
    
    <Routes>
       <Route path="/" element = {<AboutUs />}/>
       <Route path="/services" element = {<Services />}/>
       <Route path="/team" element = {<TeamPage />}/>
       <Route path="/contactus" element = {<ContactUs />}/>
       
       
    </Routes>
    
    <Footer/>
    </div>
  );
}

export default App;



