import React from "react";
import ContactModal from "../components/ContactModal";


const ContactUs = () => {




    return (
        
        <div className="contactUs"><br/><br/>
           
            <h1> Contact Us</h1>
            <p>To have one of our team members contact you, please fill out the form below.  <br/> A team member will reach out to you as soon as possible.</p> 
           
            <ContactModal/>

        </div>
        
    );
};

export default ContactUs;