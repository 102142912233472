import React from "react";



const AboutUs = () => {


    return (
        <div><br/><br/>
            <h1>About Us</h1>
            <br/>
            <br/>
            <b><i>Cyberpools: Protection for pools against today’s cyber threats...and tomorrow’s</i></b><br/>
            <br/>
<p>At Cyberpools, we provide insurance pools and their members with tools to stop threat actors in their tracks and prepare for a robust response if systems or data are in danger. Enhanced security is the most direct benefit, but pools with hardened defenses against cyber threats also enjoy easier, more affordable access to insurance markets.</p>
<p>Cyberpools was created by Wes Gates, as an offshoot of Gates Consulting.  He is an IT security veteran with two decades of experience working with the Arizona School Risk Retention Trust, which insures school districts across Arizona.  From this work, he expanded to other public entity members—particularly schools.</p>  
<p>Why schools? Education is now the most targeted sector for cyber attacks. Hackers understand that the volume of sensitive personal information and the number of groups with access to school networks—students, the public, parents, staff, contractors, governing boards, and regulators—make schools an unusually attractive target with numerous potential points of entry.</p><br/>
<p className="testimonial"><b>“The cyber services provided by Gates Consulting (dba Cyberpools) have helped our members feel more confident in their cyber posture and given them and their governing boards comfort in an ever challenging and changing cyber world. The Cyberpools team has been responsive to our member’s needs, including implementing the toolkit services to members, consulting on a variety of topics, and presenting to members.” </b><span><b><br/><br/>- Ryan Cole, Associate Executive Director, Arizona School Risk Retention Trust</b></span></p>
<br/><br/>
<p>Recently, Cyberpools has partnered with Risk Program Administrators (RPA), a division of Arthur J. Gallagher & Co. The partnership connects our cybersecurity team with entities that are struggling with security, with the cyber liability insurance market, or both.</p> 
<p>Our experts have seen firsthand the difficulties of securing reasonably priced cyber coverage in the current security climate. Not only do schools and other public entities need to invest in cybersecurity measures, they must also be able to show insurance carriers that they are well protected.</p>
<p>To help schools and their insurance pools meet these challenges, Cyberpools created its core product, the cybersecurity toolkit. The toolkit can easily be rolled out to pool members to strengthen their cybersecurity posture and align with what insurance carriers are looking for. What’s more, Cyberpools makes it easy and rewarding for members to participate. Our processes and work products are:</p>

<ul>
    <li>Simple </li>
    <li>Clear </li>
    <li>Manageable </li>
    <li>Actionable </li>
    <li>Impactful </li>
</ul>

<b>Reach out today to stake a proactive approach to your cybersecurity. </b>
        </div>

    );

};

export default AboutUs;