import React from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../media/logo.png"


const Navigation = () => {
    return (

        <Navbar id="navBar" bgcolor='light'>
          
          <Navbar.Brand href="/"><img className="siteImage" alt="site logo" src={logo}/></Navbar.Brand>
          <Nav className='navBarItems' >
            
            <Nav.Link href="/"><h4 className="linkText"> About Us</h4></Nav.Link>
            <Nav.Link href="/services"><h4 className="linkText"> Services</h4></Nav.Link>
            <Nav.Link href="/team"><h4 className="linkText"> The Team</h4></Nav.Link>
            <Nav.Link href="/contactus"><h4 className="linkText"> Contact Us</h4></Nav.Link>
      
          </Nav>
        
      </Navbar>
    );
};

export default Navigation;