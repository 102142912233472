import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import siteImage from "../media/logo.png"

const Footer = () => {
  return (
    <div >
    <MDBFooter className="footer">
 

    
        <MDBContainer >
          <MDBRow >
            <MDBCol >
                <img className="siteImage2" src={siteImage} alt="site logo"></img>
            </MDBCol>

           

            <MDBCol  >
              <p>
                <a href='/' className='footerLinks'>
                  About Us
                </a></p>
              <p>
                <a href='/team' className='footerLinks'>
                  The Team
                </a>
              </p>
              <p>
                <a href='/contactus' className='footerLinks'>
                  Contact Us
                </a>
              </p>
              <p>
                <a href='Services' className='footerLinks'>
                  Services
                </a>
              </p>
            </MDBCol>

            {/* <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>1234 S Ome Street</p>
              <p>Phoenix, AZ 85000, US</p>
              <p>cyber@cyberpools.org</p>
              <p>
                <MDBIcon color='secondary' icon='phone' className='me-3' /> + 01 602 555 5555
              </p>
              <p>
                <MDBIcon color='secondary' icon='print' className='me-3' /> + 01 602 555 5555
              </p>
            </MDBCol> */}
          </MDBRow>
        </MDBContainer>
      

    </MDBFooter></div>
 
  );
}

export default Footer;