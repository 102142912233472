import React from "react";
import { useState } from 'react';
import { send } from 'emailjs-com';
import {  Modal } from 'react-bootstrap';

const ContactModal = () => {
    const [toSend, setToSend] = useState({
        from_name: '',
        message: '',
        reply_to: '',
      });
    
      const onSubmit = (e) => {
        e.preventDefault();
        send(
          'service_luxwxz8',
          'template_sml44i4',
          toSend,
          'hnXhQr-Z90nYjTMbZ'
        )
          .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
          })
          .catch((err) => {
            console.log('FAILED...', err);
          });
      };
    
      const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
      };
      const [show, setShow] = useState(false);
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);


    return (

        <div>
           <button className="button" onClick={handleShow}><b>Submit Inquiry</b></button>
        <Modal className="modalStyle"  show={show} onHide={handleClose}>
          <Modal.Header >
            <h3>Submit an Inquiry</h3>
          </Modal.Header>
          <Modal.Body  >

          <form  onSubmit={onSubmit}>
              <label >Your Name:</label>
              <input type="text" name="from_name" value={toSend.from_name}
    onChange={handleChange}></input>
              <label >Your Email Address:</label>
              <input type="text" name="reply_to" value={toSend.reply_to}
    onChange={handleChange}></input>
              <label >Reason for Inquiry <span>(required)</span>:</label>
              <textarea id="freeform" name="message" value={toSend.message}
    onChange={handleChange} >
Enter text here...
</textarea><br/>
<button className="button" type='submit' onClick={handleClose}>Submit</button>
            </form>
  

          </Modal.Body>
        </Modal></div>



    );
};

export default ContactModal;